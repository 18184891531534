.carousel-inner {
  width: 100%;
  max-height: 600px !important;
}

img {

  object-fit: fill;
}

@media (min-width: 1000px) {
  .card-horizontal {
    display: flex;
    flex: 1 1 auto;
  }
}